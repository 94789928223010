import "./App.css";
import { useEffect, useState } from "react";
import bgMusic from "./assets/bg_music.mp3";
import { loadSound, playSound, setSoundVolume } from "./utils/sound";
import CustomWheel from "./CustomWheel";
import { blazzio, leo } from "./wheels";

var bgSound = loadSound(bgMusic);

function App() {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [musicStarted, setMusicStarted] = useState(false);
  const [wheel, setWheel] = useState(leo);
  const wheels = {
    blazzio,
    leo,
  };

  useEffect(() => {
    if (musicStarted) {
      setSoundVolume(bgSound, 0.2);
      playSound(bgSound, true);
    }
  }, [musicStarted]);

  const handleSpinClick = () => {
    setMusicStarted(true);
    const newPrizeNumber = Math.floor(Math.random() * wheel.sliceCount);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  const onOptionChanged = (e) => {
    setWheel(wheels[e.target.value]);
  };

  return (
    <div className="App">
      <div className="styleSelect">
        <label style={{ color: "white", marginRight: "16px" }}>Style</label>
        <select onChange={onOptionChanged} disabled={mustSpin}>
          <option value="leo">Leo</option>
          <option value="blazzio">Blazzio</option>
        </select>
      </div>
      <CustomWheel
        prizeNumber={prizeNumber}
        mustSpin={mustSpin}
        setMustSpin={setMustSpin}
        wheel={wheel}
      />
      <button
        disabled={mustSpin}
        style={{ zIndex: 1, backgroundColor: wheel.button.background }}
        onClick={handleSpinClick}
      >
        Spin
      </button>
    </div>
  );
}

export default App;
