export function getRotationDegrees(element) {
  // get the computed style object for the element
  var style = window.getComputedStyle(element);
  // this string will be in the form 'matrix(a, b, c, d, tx, ty)'
  var transformString =
    style["-webkit-transform"] || style["-moz-transform"] || style["transform"];
  if (!transformString || transformString === "none") return 0;
  var splits = transformString.split(",");
  // parse the string to get a and b
  var parenLoc = splits[0].indexOf("(");
  var a = parseFloat(splits[0].substr(parenLoc + 1));
  var b = parseFloat(splits[1]);
  // doing atan2 on b, a will give you the angle in radians
  var rad = Math.atan2(b, a);
  var deg = (180 * rad) / Math.PI;
  // instead of having values from -180 to 180, get 0 to 360
  if (deg < 0) deg += 360;
  return deg;
}

export const getSliceNumber = (angle, sliceCount, offset) => {
  const sliceAngle = 360 / sliceCount;
  return Math.floor((angle + offset) / sliceAngle);
};
