import { Wheel } from "react-custom-roulette";
import { useLayoutEffect, useRef } from "react";
import { getRotationDegrees, getSliceNumber } from "./utils/math";
import { playSound } from "./utils/sound";
import { tickSound } from "./sounds";

function CustomWheel({ wheel, prizeNumber, mustSpin, setMustSpin }) {
  const wheelRef = useRef();
  const angle = useRef(null);

  const data = [...Array(wheel.sliceCount).keys()].map((slice) => {
    if (slice === wheel.sliceCount - 1) {
      return {
        option: slice.toString(),
        image: { uri: wheel.img, sizeMultiplier: 5, offsetY: -439 },
      };
    }
    return { option: slice.toString() };
  });

  const didHitStick = () => {
    const lineOffset = 8;

    if (wheelRef.current) {
      const currentAngle = getRotationDegrees(
        wheelRef.current.children[0].children[0]
      );
      if (
        angle.current &&
        angle.current < 360 - lineOffset &&
        getSliceNumber(angle.current, data.length, lineOffset) !==
          getSliceNumber(currentAngle, data.length, lineOffset)
      ) {
        angle.current = currentAngle;
        return true;
      }
      angle.current = currentAngle;
    }
    return false;
  };

  useLayoutEffect(() => {
    setInterval(() => {
      if (didHitStick()) {
        playSound(tickSound);
      }
    }, 30);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="CustomWheel">
      <div className="logoContainer">
        <img src={wheel.icon} alt="icon" className="logo"></img>
      </div>
      <div ref={wheelRef} style={{ transform: "rotate(45deg)" }}>
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={data}
          innerBorderWidth={300}
          outerBorderWidth={1}
          outerBorderColor="yellow"
          pointerProps={{
            src: wheel.thing,
            style: { transform: "rotate(-45deg)", translate: "-10px 10px" },
          }}
          textColors={["white"]}
          onStopSpinning={() => {
            setMustSpin(false);
          }}
        />
      </div>
    </div>
  );
}

export default CustomWheel;
