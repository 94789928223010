import wheel1 from "./assets/wheel.png";
import wheel_leo from "./assets/wheel-leo.png";
import icon from "./assets/icon.png";
import thing from "./assets/thing.png";
import thing_leo from "./assets/thing-leo.png";
import icon_leo from "./assets/icon-leo.png";

export const blazzio = {
  width: 963,
  height: 949,
  img: wheel1,
  icon,
  thing,
  sliceCount: 9,
  button: {
    background: "#aa7ee7",
  },
};

export const leo = {
  width: 1925,
  height: 1898,
  img: wheel_leo,
  icon: icon_leo,
  thing: thing_leo,
  sliceCount: 9,
  button: {
    background: "#EC9C00",
  },
};
